(function($) {

    $('.has-future-booking').on('click',function(e){
        e.preventDefault();
        var form = $(this).parents('form'),
            label  = $(this).data('label');
        swal({
            title: "Are you sure?",
            text: label,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Continue",
            closeOnConfirm: false
        }, function(isConfirm){
            if (isConfirm) form.submit();
        });
    });

    $('#booking-description-modal').on('show.bs.modal', function(e) {
        var btn = $(e.relatedTarget),
            slotId = btn.data('slot_id'),
            obstetrics = btn.data('obstetrics'),
            description = btn.data('description'),
            patient = btn.data('patient'),
            doctorName = btn.data('doctor'),
            bookingDate = btn.data('date');

        $('#patient-name').text(patient);
        $('#booking-date').text(bookingDate);
        $('#doctor-name').text(doctorName);
        $('input[name=id]').val(slotId);
        if (obstetrics) {
            $('input[name=obstetrics]').attr('checked', 1);
        }
        $('input[name=description]').val(description);
    });

    /**
    * Add a URL parameter (or changing it if it already exists)
    * @param {search} string  this is typically document.location.search
    * @param {key}    string  the key to set
    * @param {val}    string  value
    */
    var addUrlParam = function(query, key, val){

        var match,
                pl     = /\+/g,  // Regex for replacing addition symbol with a space
                search = /([^&=]+)=?([^&]*)/g,
                decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
            strings = query.split('?'),
            urlParams = {};
            while (match = search.exec(strings[1]))
               urlParams[decode(match[1])] = decode(match[2]);
           urlParams[key] = val;

      return strings[0] + '?' + $.param(urlParams);
    };

    $('.wysiwyg').tinymce({
       theme: "modern",
    });

    $(document).on('keyup', 'input[name=booking_description]', function(e) {
        var field = $(this),
            description = field.val(),
            buttons = field.parents('.popover-content').find('.btn-success'),
            checked = field.is(':checked'),
            link = checked ? 'obstetrics' : 'general',
            desc = $('input[name=description]'),
            generalLink,
            obstrLink;

        desc.val(description);

        buttons.each(function(item) {
            generalLink = $(this).data('general', addUrlParam($(this).data('general'), 'description', description));
            obstrLink = $(this).data('obstetrics', addUrlParam($(this).data('obstetrics'), 'description', description));
            $(this).prop('href', $(this).data(link));
        });
    });

    $(document).on('click', '.booking-in-progress', function(e) {
        e.preventDefault();

        let link = $(this);

        swal({
            title: 'Incomplete booking',
            text: 'It seems you want to leave the page without completing the booking process. Are you sure?',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: 'Yes',
            closeOnConfirm: false
        }, function() {
            window.location = link.attr('href');
        });
    });

    $('#calendar-nav').on('change', function () {
        var url = $(this).val();
        if (url) {
            window.location = url;
        }
        return false;
    });

    $(document).on('change', 'input[name=obstetrics_cb]', function(e) {
        var cbox = $(this),
            buttons = cbox.parents('.popover-content').find('.btn-success'),
            checked = cbox.is(':checked'),
            link = checked ? 'obstetrics' : 'general';

        buttons.each(function(item) {
            $(this).prop('href', $(this).data(link));
        });
    });

    Dropzone.autoDiscover = false;

    $('.attachments-dropzone').dropzone({
        url: $('.attachments-dropzone').data('url'),
        paramName: 'attachment',
        maxFiles: 10,
        headers: {
            'X-CSRF-TOKEN':$('input[name=_token]').val(),
            'X-UPLOAD_HASH':$('input[name=_upload_hash]').val(),
            'object_id': $('input[name=_object_id]').val(),
            'upload_hash': $('input[name=_upload_hash]').val()
        },
        acceptedFiles: "image/*,.pdf",
        init: function() {
            this.on('success', function(data, response) {
                uploader = $(this);
                preview = $('.attachments-dropzone-preview');
                uploader.addClass('hidden');
                preview.html(response);
                return;
            });
        }
    });

    $('[data-toggle="popover"]').popover({trigger: 'click', html: true, container: 'body'});

    $('#booking-modal').on('show.bs.modal', function(e) {
        var btn = $(e.relatedTarget),
            slotId = btn.data('slot_id'),
            obstetrics = btn.parents('.popover-content').find('input[name=obstetrics_cb]').is(':checked'),
            originalSlotId = btn.data('original_slot_id');

        $('input[name=slot_id]').val(slotId);
        $('input[name=original_slot_id]').val(originalSlotId);
        $('input[name=obstetrics]').val(obstetrics ? 1 : 0);
    });

    $('.load-history').on('click', function(e) {
        e.preventDefault();

        var l = $(this),
            id = l.data('id'),
            type = l.data('type'),
            modal = l.data('modal');

        $.get('/system/history/' + id + '/' + type, function(response) {
            if (response) {
                $(modal).html(response);
                $(modal).modal('show');
            }
        });
    });

    $('#doctor-selector').change(function() {
        let url = $(this).find('option:selected').data('url');
        window.location = url;
    });

    $('.chosen-patient').chosen({
        'search_contains': true,
        'placeholder_text_single': 'Please select a patient record'
    });

    $(document).on('click', '.btn-delete', function(e) {
        e.preventDefault();

        let link = $(this);

        swal({
            title: link.data('title'),
            text: link.data('warning'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: link.data('confirm'),
            closeOnConfirm: false
        }, function() {
            window.location = link.attr('href');
        });
    });

    $('.fluidimg').fluidbox();

    moment.locale('en', {
      week: { dow: 1 } // Monday is the first day of the week
    });

    $('.datepicker').datetimepicker({
        icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-crosshairs',
            clear: 'fa fa-trash',
            close: 'fa fa-times'
        },
        format: 'DD/MM/YYYY',
        debug: false
    });

    $('.datetimepicker').datetimepicker({
        icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-crosshairs',
            clear: 'fa fa-trash',
            close: 'fa fa-times'
        },
        format: 'DD/MM/YYYY HH:mm',
        debug: false
    });

    $('.timepicker').datetimepicker({
        icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-crosshairs',
            clear: 'fa fa-trash',
            close: 'fa fa-times'
        },
        format: 'HH:mm',
        debug: false
    });

    $(document).on('click', '.popover-title span', function() {
        var pId = $(this).parents('.popover').attr('id');
        $(document).find('[aria-describedby=' + pId + ']').trigger('click');
    });

})(jQuery);
